import { Box, Button, Heading, Spinner } from "@chakra-ui/react";
import {
  PayPalButtons,
  SCRIPT_LOADING_STATE,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCaptureOrder, useCreateOrder } from "../api/mutations";
import { TicketOrderForm } from "./ticket-order-form";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import faker from "@faker-js/faker";

interface TicketOrderFormValues {
  licenseNumber: string;
  email: string;
  confirmEmail: string;
  firstName: string;
  lastName: string;
  street?: string;
  houseNumber?: string;
  zipcode?: string;
  city?: string;
  validFrom?: Date;
}

enum TicketPurchasePageStep {
  DataEntry = "DataEntry",
  CatchListPending = "CatchListPending",
  Payment = "Payment",
}

interface TicketPurchasePageState {
  step: TicketPurchasePageStep;
  orderFormValues?: TicketOrderFormValues;
}

const fillWithMockValues = (
  setValues: (values: TicketOrderFormValues) => void
) => {
  const email = faker.internet.exampleEmail();
  setValues({
    licenseNumber: faker.random.alphaNumeric(8),
    email: email,
    confirmEmail: email,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    city: faker.address.cityName(),
    street: faker.address.streetName(),
    houseNumber: faker.random.alphaNumeric(3),
    zipcode: faker.address.zipCode(),
  });
};

const OrderFormValidationSchema = Yup.object().shape({
  licenseNumber: Yup.string()
    .min(1, "Gib bitte eine Erlaubnisschein-Nummer ein.")
    .max(50, "Diese Erlaubnisschein-Nummer ist zu lang.")
    .required("Gib bitte eine Erlaubnisschein-Nummer ein."),
  email: Yup.string()
    .email("Gib bitte eine gültige E-Mail-Adresse ein.")
    .required("Gib bitte eine gültige E-Mail-Adresse ein."),
  confirmEmail: Yup.string()
    .required("Bestätige bitte deine E-Mail-Adresse ein.")
    .test(
      "emails-match",
      "Die beiden E-Mail-Adressen müssen übereinstimmen",
      function (value) {
        return this.parent.email === value;
      }
    ),
  firstName: Yup.string()
    .min(1, "Gib bitte einen Vornamen ein.")
    .max(120, "Gib bitte einen kürzeren Vornamen ein.")
    .required("Gib bitte einen Vornamen ein."),
  lastName: Yup.string()
    .min(1, "Gib bitte einen Nachnamen ein.")
    .max(50, "Gib bitte einen kürzeren Nachnamen ein.")
    .required("Gib bitte einen Nachnamen ein."),
  street: Yup.string()
    .min(1, "Gib bitte einen Straßennamen ein.")
    .max(200, "Dieser Straßenname ist zu lang.")
    .required("Gib bitte einen Straßennamen ein."),
  houseNumber: Yup.string()
    .min(1, "Gib bitte eine Hausnummer ein.")
    .max(10, "Diese Hausnummer ist zu lang.")
    .required("Gib bitte eine Hausnummer ein."),
  zipcode: Yup.string()
    .min(1, "Gib bitte eine Postleitzahl ein.")
    .max(20, "Diese Postleitzahl ist zu lang.")
    .required("Gib bitte eine Postleitzahl ein."),
  city: Yup.string()
    .min(1, "Gib bitte eine Stadt ein.")
    .max(120, "Dieser Stadtname ist zu lang.")
    .required("Gib bitte eine Stadt ein."),
  validFrom: Yup.string().optional(),
});

export const TicketPurchase = () => {
  const [state, setState] = useState<TicketPurchasePageState>({
    step: TicketPurchasePageStep.DataEntry,
  });
  const [paypal, dispatchPaypalAction] = usePayPalScriptReducer();
  const createOrderMutation = useCreateOrder();
  const captureOrderMutation = useCaptureOrder();
  const navigate = useNavigate();

  useEffect(() => {
    if (paypal.isInitial && state.step === TicketPurchasePageStep.Payment) {
      dispatchPaypalAction({
        type: "setLoadingStatus",
        value: SCRIPT_LOADING_STATE.PENDING,
      });
    }
  }, [paypal, dispatchPaypalAction, state]);

  return (
    <Box>
      <Heading mb={8}>Tageskarte kaufen</Heading>
      {state.step === TicketPurchasePageStep.DataEntry && (
        <Formik<TicketOrderFormValues>
          initialValues={{
            licenseNumber: "",
            email: "",
            confirmEmail: "",
            firstName: "",
            lastName: "",
            city: "",
            street: "",
            houseNumber: "",
            zipcode: "",
          }}
          validationSchema={OrderFormValidationSchema}
          validateOnChange
          validateOnMount
          onSubmit={(orderFormValues) => {
            setState({
              step: TicketPurchasePageStep.Payment,
              orderFormValues,
            });
          }}
        >
          {({ setValues, handleSubmit }) => (
            <Box as="form" onSubmit={handleSubmit as any}>
              {process.env.NODE_ENV === "development" && (
                <Box marginBottom={8}>
                  <Button
                    backgroundColor="red"
                    onClick={() => {
                      fillWithMockValues(setValues);
                    }}
                  >
                    DEV - Mock Values
                  </Button>
                </Box>
              )}
              <TicketOrderForm />
              <Button
                my={4}
                type="submit"
                colorScheme="green"
                w="100%"
                variant="solid"
              >
                Zur Bezahlung
              </Button>
            </Box>
          )}
        </Formik>
      )}
      {state.step === TicketPurchasePageStep.Payment && (
        <Box>
          {paypal.isPending && <Spinner size="lg" />}
          {paypal.isResolved && (
            <PayPalButtons
              forceReRender={[state]}
              createOrder={async () => {
                const { ecToken } = await createOrderMutation.mutateAsync({
                  ...state.orderFormValues,
                  validFrom: new Date(),
                });
                return ecToken;
              }}
              onApprove={async (paypalData) => {
                const result = await captureOrderMutation.mutateAsync({
                  orderId: paypalData.orderID,
                });
                navigate(`/angelkarten/download/${result.ticketId}`);
              }}
              onError={(e) => {
                console.error("Paypal error", e);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
