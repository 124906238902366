import { useParams } from "react-router-dom";
import { useGetTicketInfoById } from "../api/queries";

interface TicketValidationParams {
  ticketId: string;
  [key: string]: string;
}

export const TicketValidation = () => {
  const { ticketId } = useParams<TicketValidationParams>();
  const {
    data: ticket,
    isSuccess: ticketExists,
    isFetching,
  } = useGetTicketInfoById(ticketId!);

  if (isFetching) {
    return <p>Loading...</p>;
  }

  if (ticketExists) {
    return (
      <p>
        {ticketId} gültig, {JSON.stringify(ticket)}
      </p>
    );
  } else {
    return <p>{ticketId} ungültig</p>;
  }
};
