export const FISH_TYPES = [
    "Aal",
    "Äsche",
    "Bachforelle",
    "Barbe",
    "Blei",
    "Barsch",
    "Döbel",
    "Giebel",
    "Hecht",
    "Karausche",
    "Karpfen",
    "Quappe",
    "Plötze",
    "Rotfeder",
    "Schleie",
    "Wels",
    "Zander",
    "Sonstige Fischart",
];

export type CaughtAndKept = {
    type: string;
    count: number;
};

export type CaughtListEntry = {
    date: Date;
    timeFrom: Date;
    timeTo: Date;
    section: "1" | "2" | "3";
    caughtAndKept: CaughtAndKept[];
    isEditing?: boolean;
};
