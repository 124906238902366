import "@fontsource/open-sans/700.css";
import "@fontsource/source-sans-3";

import { Box, ChakraProvider } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "./components/header";
import { AppRoutes } from "./app-routes";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalConfig } from "./paypal-config";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./query-client";
import { appTheme } from "./theme";

function App() {
  return (
    <PayPalScriptProvider options={paypalConfig} deferLoading>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={appTheme}>
          <Router>
            <Box>
              <Header />
              <Box p={4}>
                <AppRoutes />
              </Box>
            </Box>
          </Router>
        </ChakraProvider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  );
}

export default App;
