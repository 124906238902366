import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Link,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useGetTicketInfoById } from "../api/queries";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface TicketDownloadParams {
  ticketId: string;
  [key: string]: string;
}

export const TicketDownload = () => {
  const { ticketId } = useParams<TicketDownloadParams>();
  const { data } = useGetTicketInfoById(ticketId!);

  return (
    <Box>
      <Alert status="success" variant="solid" mb={8}>
        <AlertIcon />
        <AlertTitle mr={2}>Dein Download ist bereit!</AlertTitle>
        <AlertDescription>
          Klicke auf den unten stehenden Link, um deine Angelkarte
          herunterzuladen
        </AlertDescription>
      </Alert>
      <Box>
        <Link href={data?.downloadLink} target="_blank" isExternal>
          Ticket als PDF <ExternalLinkIcon mx="2px" />
        </Link>
      </Box>
      <Alert status="warning" variant="solid" mb={8}>
        <AlertIcon />
        <AlertTitle mr={2}>Bitte die Fangliste ausfüllen!</AlertTitle>
        <AlertDescription>
          Klicke hier, um zur Fangliste für deine Tageskarte zu gelangen
        </AlertDescription>
      </Alert>
      <Box>
        <Link href={`/fanglisten/${ticketId}`} target="_blank">
          Fangliste <ExternalLinkIcon mx="2px" />
        </Link>
      </Box>
    </Box>
  );
};
