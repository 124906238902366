import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <Box display="flex" justifyContent="space-between" p={4}>
      <Box display="flex">
        <Box mr={4}>
          <Link to="/angelkarten">Angelkarten</Link>
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={4}>
          <Link to="/impressum">Impressum</Link>
        </Box>
        <Box mr={4}>
          <Link to="/datenschutz">Datenschutz</Link>
        </Box>
      </Box>
    </Box>
  );
};
