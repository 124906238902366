import { Box, Button, Heading, SimpleGrid, Spinner } from "@chakra-ui/react";
import { FieldArray, Formik, FormikErrors, FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { useSubmitCatchList } from "../api/mutations";
import { FISH_TYPES } from "../models";
import { InputControl, SelectControl } from "formik-chakra-ui";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { v4 } from "uuid";
import * as Yup from "yup";
import { RadioButtonGroupControl } from "../components/form-controls/radio-button-group-control";
import { Fragment } from "react";
import { Card } from "../components/card";
import { useGetTicketInfoById } from "../api/queries";
import { UpdateCatchListDto } from "../api/catch-list.dto";

interface SubmitCatchListParams {
  ticketId: string;
  [key: string]: string;
}

type Catch = {
  fishType: string | null;
  sizeCentimeters: number | string | null;
  id: string;
};

type CatchListFormValues = {
  catches: Catch[];
  didCatchAndRemoveFish: "yes" | "no" | null;
};

const produceNewCatch = () => ({
  sizeCentimeters: "",
  id: v4(),
  fishType: "default",
});

const renderCatchesFieldArray =
  (props: FormikProps<CatchListFormValues>) => (arrayHelpers: any) => {
    return (
      <SimpleGrid columns={1} spacing={4}>
        {props.values.catches?.map((catchEntry, idx) => {
          const displayIndex = idx + 1;
          const fishType =
            catchEntry.fishType !== "default" ? catchEntry.fishType : null;
          const touched = props.touched?.catches?.[idx];
          const errors = props.errors?.catches?.[idx] as FormikErrors<Catch>;
          const isShowingErrors =
            (touched?.fishType && errors?.fishType) ||
            (touched?.sizeCentimeters && errors?.sizeCentimeters);
          return (
            <Card key={catchEntry.id}>
              <Heading size="sm" mb={4}>
                Fang {displayIndex} {fishType && `- ${fishType}`}
              </Heading>
              <SimpleGrid
                columns={{ sm: 1, md: 3 }}
                spacing={4}
                key={catchEntry.id}
              >
                <SelectControl
                  name={`catches[${idx}].fishType`}
                  label="Fischart"
                  placeholder="Bitte auswählen"
                  defaultValue="default"
                  isRequired
                >
                  <option disabled value="default">
                    Bitte auswählen
                  </option>
                  {FISH_TYPES.map((type) => (
                    <option label={type} value={type} key={type} />
                  ))}
                </SelectControl>
                <InputControl
                  isRequired
                  name={`catches[${idx}].sizeCentimeters`}
                  label="Größe in cm"
                  mb={{ sm: 4, md: 0 }}
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: 500,
                    step: 1,
                  }}
                />
                <Box display="flex" alignItems="flex-end">
                  <Button
                    mb={{ sm: 0, md: isShowingErrors ? 8 : 0 }}
                    leftIcon={<DeleteIcon />}
                    colorScheme="red"
                    type="button"
                    aria-label="Fang entfernen"
                    w="100%"
                    disabled={props.values.catches.length <= 1}
                    onClick={() => {
                      arrayHelpers.remove(idx);
                    }}
                  >
                    Fang {displayIndex} entfernen
                  </Button>
                </Box>
              </SimpleGrid>
            </Card>
          );
        })}

        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          type="button"
          onClick={() => {
            arrayHelpers.push(produceNewCatch());
          }}
        >
          Fang hinzufügen
        </Button>
      </SimpleGrid>
    );
  };

const fishTypeShape = (msg: string) =>
  Yup.string().required(msg).oneOf(FISH_TYPES, msg);

const sizeCentimetersShape = (msg: string) =>
  Yup.number().required(msg).min(1, msg).integer(msg).max(500, msg);

const catchesShape = () =>
  Yup.array().of(
    Yup.object().shape({
      fishType: fishTypeShape("Wähle bitte eine Fischart aus."),
      sizeCentimeters: sizeCentimetersShape(
        "Gib bitte eine ganze Zahl zwischen 1 und 500 ein."
      ),
    })
  );

const CatchListValidationSchema = Yup.object().shape({
  didCatchAndRemoveFish: Yup.string()
    .nullable()
    .required("Gib bitte an, ob Fische gefangen und entnommen wurden.")
    .oneOf(
      ["yes", "no"],
      "Gib bitte an, ob Fische gefangen und entnommen wurden."
    ),
  catches: Yup.array().when("didCatchAndRemoveFish", {
    is: "yes",
    then: catchesShape(),
  }),
});

export const SubmitCatchList = () => {
  const { ticketId } = useParams<SubmitCatchListParams>();
  const submitCatchListMutation = useSubmitCatchList();
  const { data: ticket, isLoading: ticketIsLoading } = useGetTicketInfoById(
    ticketId!,
    {
      enabled: !!ticketId,
    }
  );

  if (ticketIsLoading) {
    return <Spinner />;
  }

  if (!ticket) {
    return <p>ticket not found</p>;
  }

  return (
    <Formik<CatchListFormValues>
      initialValues={{
        catches:
          ticket.catchList?.didCatchAndRemoveFish === "yes"
            ? ticket.catchList?.catches
            : [produceNewCatch()],
        didCatchAndRemoveFish: ticket.catchList?.didCatchAndRemoveFish || null,
      }}
      onSubmit={async (values) => {
        let catches: UpdateCatchListDto["catches"];
        if (values.didCatchAndRemoveFish === "yes") {
          catches = values.catches.map((c) => ({
            id: c.id,
            fishType: c.fishType as string,
            sizeCentimeters: c.sizeCentimeters as number,
          }));
        }
        const data: UpdateCatchListDto = {
          didCatchAndRemoveFish: values.didCatchAndRemoveFish,
          catches,
        };

        await submitCatchListMutation.mutateAsync({
          ticketId: ticketId!,
          data,
        });
      }}
      validationSchema={CatchListValidationSchema}
    >
      {(props) => {
        return (
          <Box as="form" onSubmit={props.handleSubmit as any}>
            <Heading as="h1" size="lg">
              Fangliste für Tageskarte {ticketId}
            </Heading>
            <Heading as="h2" size="md" my={8}>
              Wurden Fische gefangen und entnommen?
            </Heading>
            <Box mb={8}>
              <RadioButtonGroupControl
                name="didCatchAndRemoveFish"
                options={[
                  {
                    value: "yes",
                    label: "Ja, ich habe Fische gefangen und mitgenommen.",
                  },
                  {
                    value: "no",
                    label: "Nein, ich habe keine Fische mitgenommen.",
                  },
                ]}
              />
            </Box>
            {props.values.didCatchAndRemoveFish === "yes" && (
              <Fragment>
                <Heading as="h2" size="md" my={8}>
                  Welche Fische wurden gefangen und entnommen?
                </Heading>
                <Box mb={8}>
                  <FieldArray
                    name="catches"
                    render={renderCatchesFieldArray(props)}
                  />
                </Box>
              </Fragment>
            )}
            <Box mb={4}>
              <Button
                w="100%"
                colorScheme="green"
                type="submit"
                disabled={props.isSubmitting}
              >
                Fangliste speichern
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
