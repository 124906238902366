import { Outlet } from "react-router-dom"
import { Route, Routes } from "react-router-dom"
import { TicketDownload } from "./ticket-download"
import { TicketPurchase } from "./ticket-purchase"
import { TicketValidation } from "./ticket-validation"

export const Tickets = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<TicketPurchase />} />
                <Route path="download/:ticketId" element={<TicketDownload />} />
                <Route path="gueltigkeit/:ticketId" element={<TicketValidation />} />
            </Routes>

            <Outlet />
        </>
    )
}