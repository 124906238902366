import { useQuery, UseQueryOptions } from "react-query";
import { apiClient } from "./api-client";
import { QueryKeys } from "./query-keys";

type TicketResponseDto = {
  downloadLink: string;
  catchList: any;
};

export const useGetTicketInfoById = (
  ticketId: string,
  options?: UseQueryOptions<unknown, any, TicketResponseDto, string[]>
) =>
  useQuery<unknown, unknown, TicketResponseDto, string[]>(
    [QueryKeys.GetTicketById, ticketId],
    async () => {
      try {
        const ticketData = await apiClient.get(`/tickets/${ticketId}`);
        console.log({ ticketData });
        return ticketData.data;
      } catch (e) {
        console.error(`Unknown ticket id: ${ticketId}`);
      }
    },
    {
      ...options,
      staleTime: 60000,
    }
  );
