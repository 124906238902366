import { Box, BoxProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

type CardProps = PropsWithChildren<BoxProps>;
export const Card: FC<CardProps> = (props) => {
  return (
    <Box borderWidth="1px" borderRadius="md" boxShadow="md" p={4} {...props}>
      {props.children}
    </Box>
  );
};
