export const paypalConfig = {
    "client-id": "test",
    currency: "EUR",
    intent: "capture",
    components: 'buttons',
    'buyer-country': 'DE',
    commit: true,
    debug: false,
    locale: 'de_DE',
    "merchant-id": '3TPNWFRVUZNVC' //sandbox merchant
}