import { useMutation } from "react-query";
import { apiClient } from "./api-client";
import { queryClient } from "../query-client";
import { QueryKeys } from "./query-keys";
import { UpdateCatchListDto } from "./catch-list.dto";

export class ApiError extends Error {
  constructor(
    public message: string,
    public statusCode: number,
    public requestUrl: string
  ) {
    super(message);
  }
}

export const useCreateOrder = () =>
  useMutation(async (args: any) => {
    const res = await apiClient.post("/ticket-orders", args);
    if (res.status !== 201) {
      throw new ApiError(res.data, res.status, res.config.url!);
    }
    return res.data;
  });

export const useCaptureOrder = () =>
  useMutation(async (args: { orderId: string }) => {
    const res = await apiClient.post(`/ticket-orders/${args.orderId}/captures`);
    if (res.status !== 201) {
      throw new ApiError(res.data, res.status, res.config.url!);
    }
    return res.data;
  });

export const useSubmitCatchList = () =>
  useMutation(
    async (args: { data: UpdateCatchListDto; ticketId: string }) => {
      const res = await apiClient.put(
        `/tickets/${args.ticketId}/catch-list`,
        args.data
      );
      if (res.status !== 200) {
        throw new ApiError(res.data, res.status, res.config.url!);
      }
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          QueryKeys.GetTicketById,
          variables.ticketId,
        ]);
      },
    }
  );
