import {
  Box,
  RadioProps,
  SimpleGrid,
  Text,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { BaseProps, FormControl } from "formik-chakra-ui";
import { FC } from "react";

const RadioButton = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        mb={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type RadioButtonGroupProps = {
  name: string;
  id: string;
  onChange: (e: string) => void;
  value: string | null;
  options: Array<{
    value: string;
    label: string;
  }>;
};
const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    onChange: (newValue) => {
      props.onChange(newValue);
    },
    value: props.value as any,
  });

  const group = getRootProps();

  return (
    <SimpleGrid {...group} columns={{ sm: 1, md: 2 }} spacing={4}>
      {props.options.map((option) => {
        const radio = getRadioProps({
          value: option.value,
        });
        return (
          <RadioButton key={option.value} {...radio}>
            <Box display="flex" alignItems="center">
              <Text>{option.label}</Text>
            </Box>
          </RadioButton>
        );
      })}
    </SimpleGrid>
  );
};

export type RadioButtonGroupControlProps = BaseProps & {
  radioButtonGroupProps?: RadioButtonGroupProps;
  options: Array<{
    value: string;
    label: string;
  }>;
};

export const RadioButtonGroupControl: FC<RadioButtonGroupControlProps> = (
  props
) => {
  const { name, label, radioButtonGroupProps, ...rest } = props;
  const [field] = useField(name);

  const { setFieldValue } = useFormikContext();
  const handleChange = (value: string) => {
    setFieldValue(name, value);
  };

  return (
    <FormControl name={name} label={label} {...rest}>
      <RadioButtonGroup
        {...field}
        {...radioButtonGroupProps}
        options={props.options}
        id={name}
        onChange={handleChange}
      />
    </FormControl>
  );
};
