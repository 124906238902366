import { Box, Link, SimpleGrid, Text } from "@chakra-ui/react";

export const Imprint = () => {
  return (
    <SimpleGrid>
      <Box>
        <Text>
          Favicon und App-Icons wurden mit{" "}
          <Link href="https://favicon.io" isExternal>
            favicon.io
          </Link>{" "}
          auf Basis von{" "}
          <Link isExternal href="https://twemoji.twitter.com/">
            Twemoji
          </Link>{" "}
          (Twitter Inc and Others) erstellt.
        </Text>
      </Box>
    </SimpleGrid>
  );
};
