import { Outlet, Route, Routes } from "react-router-dom";
import { SubmitCatchList } from "./submit-catch-list";

export const CatchLists = () => {
  return (
    <>
      <Routes>
        <Route path="/:ticketId" element={<SubmitCatchList />} />
      </Routes>

      <Outlet />
    </>
  );
};
