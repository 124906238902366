import { Route, Routes } from "react-router-dom";
import { Imprint } from "./imprint";
import { CatchLists } from "./catch-lists/catch-lists";
import { Tickets } from "./tickets/tickets";
import { DataProtection } from "./data-protection";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/fanglisten/*" element={<CatchLists />} />
      <Route path="/angelkarten/*" element={<Tickets />} />
      <Route path="/impressum" element={<Imprint />} />
      <Route path="/datenschutz" element={<DataProtection />} />
    </Routes>
  );
};
